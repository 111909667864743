<template>
  <div class="login-form">
    <v-row>
      <v-col>
        <h1>Login</h1>
      </v-col>
    </v-row>
    <v-form @submit.prevent="requestLogin" v-if="step === 1">
      <v-row>
        <v-col cols="12" v-if="error">
          <v-alert type="error" dismissible>
            {{ error }}
          </v-alert>
        </v-col>
        <v-col cols=12>
          <v-text-field outlined type="tel" label="Enter Your Mobile Phone Number" maxlength="14" @input="enforcePhoneFormat" v-model="phoneNumber" />
          <p>You will be sent an SMS with a unique login code.</p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6">
          <v-btn color="#84C9C8" block dark x-large rounded type="submit">Next</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-form @submit.prevent="verifyLogin" v-if="step === 2">
      <v-row>
        <v-col>
          <p>Enter Your Unique Login Code</p>
          <v-otp-input
            v-model="verifyCode"
            length="4"
          ></v-otp-input>
          <p>If you did not receive a code and it has been more than 2 minutes, <a @click="step = 1">click here</a> to enter you phone number again.</p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6">
          <v-btn color="#84C9C8" block dark x-large rounded type="submit">Next</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',

  data() {
    return {
      step: 1,
      phoneNumber: null,
      verifyCode: null,
      error: null,
    }
  },

  computed: {
    participant() {
      return this.$store.state.participant.keyedById[this.$store.state.login.participantId]
    }
  },

  methods: {
    async requestLogin() {
      await this.$store.dispatch('participant-login/create', {
        phoneNumber: this.phoneNumber.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
      }).then(res => {
        this.$store.state.login = res
        this.step = 2
      }).catch(err => {
        this.error = err
        console.log(err)
      })
    },

    async verifyLogin() {
      if (this.verifyCode === this.$store.state.login.code) {
        await this.$store.dispatch('participant/get', this.$store.state.login.participantId)
          .then(() => {
            this.$store.commit('setLoginSession', this.participant)
            this.$router.push({ name: 'Dashboard' })
          })
      } else {
        this.error = 'The verification code does not match the one sent to your phone number.'
      }
    },

    enforcePhoneFormat() {
      let x = this.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    }
  }
}
</script>

<style lang="scss">

</style>